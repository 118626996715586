import React from 'react'
import './Grid2Columns.css'

function Grid2Columns (props) {
  return (
    <div className="Grid2Columns">{props.children}</div>
  )
}

export default Grid2Columns
