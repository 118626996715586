import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './ModalWrapper.css'
import GridCenter from '../layouts/GridCenter'

const ModalWrapper = props => {
  return (
    <div className="ModalWrapper">
      <GridCenter>
        <div className="ModalWrapper-inner">
          <button onClick={props.close} className="ModalWrapper-close">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {props.children}
        </div>
      </GridCenter>
    </div>
  )
}
export default ModalWrapper
