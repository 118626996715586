import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import MD5 from 'md5-es'
import { default as IndexPage } from '../../components/pages/Index'
import { default as ErrorPage } from '../../components/pages/Error'
import Header from '../../components/Header'
import ContentBlock from '../../components/ContentBlock'
import ContentWithSidebar from '../../components/layouts/ContentWithSidebar'
import PageSpousalSupport from '../../containers/interest-calculator/PageSpousalSupport'
import PageChildSupport from '../../containers/interest-calculator/PageChildSupport'
import Tracker from './Tracker'

const testing = 'development' === process.env.NODE_ENV;
const tracking = !testing;
const IndexRouter = () => (
  <Router>
    <div className="IndexRouter">
      <Switch>
        <Route exact path='/' component={IndexPage} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/free/child-support-interest-calculator' component={PageChildSupport} />
        <Route exact path='/free/spousal-support-interest-calculator' component={PageSpousalSupport} />
        {/*!testing && <PrivateRoute path='/free/child-support-interest-calculator/' component={PageChildSupport} />*/}
        {/*!testing && <PrivateRoute path='/free/spousal-support-interest-calculator/' component={PageSpousalSupport} />*/}
        <Route component={ErrorPage} />
      </Switch>
      {tracking && <Tracker />}
    </div>
  </Router>
)

const fakeAuth = {
  isAuthenticated: false,
  authenticate(authValue,success, error) {
    const passed = '5101b1564c3e55fc36075e6bbc5a4d58' === MD5.hash(authValue)
    if (passed) {
      this.isAuthenticated = true
      success()
    } else {
      this.isAuthenticated = false
      error()
    }
  },
  signout(cb) {
    this.isAuthenticated = false
    cb()
  }
}

  /*
const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      fakeAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
              state: {from: props.location}
          }}
        />
      )
    }
  />
)
*/

class Login extends Component {
  state = {
    redirectToReferrer: false,
    authError: false
  }
  login = (authValue) => {
    fakeAuth.authenticate(
      authValue,
      () => {
        this.setState({redirectToReferrer: true})
      },
      () => {
        this.setState({authError: true})
      }
    )
  }

  render () {
    const { from } = this.props.location.state || { from: { pathname: '/free/child-support-interest-calculator/'} }
    const { redirectToReferrer } = this.state
    let usernameInput, passwordInput
    if (redirectToReferrer) {
      return <Redirect to={from} />
    }
    return (
      <div>
        <Header />
        <ContentWithSidebar>
          <ContentBlock>
            <form onSubmit={e => {
              e.preventDefault()
              this.login(usernameInput.value + ':' + passwordInput.value)
            }}>
              {this.state.authError &&
                <div className="AppInput error">
                  <p><strong>Error:</strong> Incorrect username/password combination.</p>
                </div>
              }
              <div className="AppInput">
                <label htmlFor="username">Username</label>
                <input id="username" type="text" ref={el => usernameInput = el} />
              </div>
              <div className="AppInput">
                <label htmlFor="password">Password</label>
                <input id="password" type="password" ref={el => passwordInput = el} />
              </div>

              <div className="AppSubmit">
                <button className="dark" type="submit">Log in</button>
              </div>
            </form>
          </ContentBlock>
        </ContentWithSidebar>
      </div>
    )

  }
}

export default IndexRouter
