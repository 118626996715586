import * as ActionTypes from '../constants/ActionTypes'

/**
 * action creators
 */

// ui actions

export function scrollToElement (selector) {
  if (selector.id) {
    const el = document.getElementById(selector.id)
    if (el) window.scrollTo({
      top: el.scrollHeight,
      behavior: 'smooth'
    })
  }
  return {
    type: ActionTypes.SCROLL_TO_ELEMENT,
    selector
  }
}

// modal actions

export function openAddPaymentsDueModal () {
  return {
    type: ActionTypes.OPEN_ADD_PAYMENTS_DUE_MODAL
  }
}

export function openAddPaymentsMadeModal () {
  return {
    type: ActionTypes.OPEN_ADD_PAYMENTS_MADE_MODAL
  }
}

export function openEditCalculatorItemModal (id) {
  return {
    type: ActionTypes.OPEN_EDIT_CALCULATOR_ITEM_MODAL,
    id
  }
}

export function openEditCalculatorItemsModal (ids) {
  return {
    type: ActionTypes.OPEN_EDIT_CALCULATOR_ITEMS_MODAL,
    ids
  }
}

export function openConfirmDeleteItemModal (id) {
  return {
    type: ActionTypes.OPEN_CONFIRM_DELETE_ITEM_MODAL,
    id
  }
}

export function openConfirmDeleteSelected (ids) {
  return {
    type: ActionTypes.OPEN_CONFIRM_DELETE_SELECTED_MODAL,
    ids
  }
}

export function closeModal () {
  return {
    type: ActionTypes.CLOSE_MODAL
  }
}

// payment item actions

export function addPaymentsDue (paymentGroupDue) {
  return {
    type: ActionTypes.ADD_PAYMENTS_DUE,
    paymentGroupDue
  }
}

export function addPaymentsMade (paymentGroupMade) {
  return {
    type: ActionTypes.ADD_PAYMENTS_MADE,
    paymentGroupMade
  }
}

export function editCalculatorItem (item) {
  return {
    type: ActionTypes.EDIT_CALCULATOR_ITEM,
    item
  }
}

export function editCalculatorItemsAmounts (amount) {
  return {
    type: ActionTypes.EDIT_CALCULATOR_ITEMS_AMOUNTS,
    amount
  }
}

export function deleteCalculatorItem (item) {
  return {
    type: ActionTypes.DELETE_CALCULATOR_ITEM,
    item
  }
}

export function deleteCalculatorItems (items) {
  return {
    type: ActionTypes.DELETE_CALCULATOR_ITEMS,
    items
  }
}
// current date actions
export function updateCurrentDateISO (currentDateISO) {
  return {
    type: ActionTypes.UPDATE_CURRENT_DATE_ISO,
    currentDateISO
  }
}
// configuration actions
export function configureInterestCalculator (settings) {
  return {
    type: ActionTypes.CONFIGURE_INTEREST_CALCULATOR,
    settings
  }
}

