import React, { Component } from 'react'
import './GridCenter.css'

class GridCenter extends Component {
  render () {
    return (
      <div className={"GridCenter" + (this.props.version ? " ver-" + this.props.version : "")}>
        <div className="GridCenter-item">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default GridCenter
