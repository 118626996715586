import React from 'react'
import knowledgeBaseImage from '../../assets/images/at-the-library-faragoza.jpg'
const InterestCalculatorPage = {
  'content-block': {
    'title': 'Free Spousal Support Interest Calculator',
    'body': [
      'Welcome! If you or your ex owes past-due spousal support (also known as spousal maintenance or alimony) according to a valid support order issued by a Colorado Court, interest may apply to increase the debt.',
      ['Colorado law currently sets interest on spousal support / spousal maintenance debts at 8% ', <em key="em">compounded annually</em>, ', the normal default statutory interest rate. Calculation of interest requires some complicated math, which our calculator can take care of for you. To calculate your spousal support debt and to see how much interest may apply, use the calculator below. To learn more about spousal support interest, go to our Knowledge Center.']
    ],
    'cta-buttons': [
      /*{
        'text': 'Guide Me'
      },*/
      {
        'text': 'Start Calculation',
        'dark': true,
        'actionName': 'scrollToElement',
        'payload': {
          'id': 'interest-calculator'
        }
      }
    ]
  },
  'article-preview': {
    'category': 'Knowledge Base',
    'title': 'Learn About What Determines Spousal Support Interest Calculations In Colorado',
    'excerpt': '',
    'url': 'https://www.legalthunderapps.com/knowledge-base/',
    'image': <img src={knowledgeBaseImage} alt="Knowledge Base" />

  },
  intro: {
    'content-block': {
      'title': 'Spousal Support Interest Calculator',
      'body': ['To calculate your total spousal support debt, you need to enter the amount and due date of each payment according to the Support Order, then enter the actual amount and actual date of each payment that was made. You can enter this information individually or in groups. You can also edit your entries. Click below to begin.'],
      'cta-buttons': [
        {
          'text': 'Enter Payments Due',
          'actionName': 'openAddPaymentsDueModal'
        },
        {
          'text': 'Enter Payments Made',
          'actionName': 'openAddPaymentsMadeModal'
        }
      ]
    }
  }
}

export default InterestCalculatorPage
