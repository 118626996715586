import React, { Component } from 'react'
import './Header.css'
import logo from '../assets/logo-placeholder.png'
import MainMenu from './MainMenu'

class Header extends Component {
  render () {
    switch (this.props.version) {

      case 'center':
        return (
          <header className="Header ver-center">
            <h1 className="Header-title">Legal </h1>
            <img className="Header-logo" src={logo} alt="Legal Thunder Logo" />
            <h1 className="Header-title"> Thunder</h1>
          </header>
        )

      default:
        return (
          <header className="Header">
            <a className="Header-brand-link" href="https://www.legalthunderapps.com">
              <img className="Header-logo" src={logo} alt="Legal Thunder Logo" />
              <h1 className="Header-title">Legal Thunder</h1>
            </a>
            <MainMenu />
          </header>
        )

    }
  }
}

export default Header
