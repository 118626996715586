import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteCalculatorItems } from '../../store/actions'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import ModalWrapper from '../../components/modals/ModalWrapper'

class ConfirmDeleteItemsFormComponent extends Component {
  handleFormSubmit = e => {
    e.preventDefault()
    const { dispatch, items } = this.props
    dispatch(deleteCalculatorItems(items))
  }

  render () {
    return (
      <ModalWrapper {...this.props}>
        <form className="ConfirmDeleteItemsForm" onSubmit={this.handleFormSubmit}>
          <h3 className="AppFormTitle">Confirm Delete Items</h3>
          <div className="AppInput">
            <p>Are you sure you would like to delete <em>{this.props.items.length}</em> item(s)?</p>
          </div>
          <Grid2Columns>
            <div className="AppSubmit">
              <button className="dark" type="submit">Delete</button>
            </div>
          </Grid2Columns>
        </form>
      </ModalWrapper>
    )
  }
}

let ConfirmDeleteItemsForm = connect()(ConfirmDeleteItemsFormComponent)

export default ConfirmDeleteItemsForm
