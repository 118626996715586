/*
 * sorting functions
 */

// a & b must be items with 'date' key moment instance
export function chronologically (a, b) {
  if (a.date.isBefore(b.date)) return -1
  else if (b.date.isBefore(a.date)) return 1
  else return 0
}
