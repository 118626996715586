import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown, faPencilAlt, faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import { CompoundingModels } from '../../store/reducers'
import './ItemList.css'

import { presentMoment, presentDollarAmount } from '../../helpers/presenters'

class ItemListComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      selected: [],
      bulkAction: ''
    }
    this.toggleList = this.toggleList.bind(this)
    this.select = this.select.bind(this)
    this.unselect = this.unselect.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.selectNone = this.selectNone.bind(this)
  }

  toggleList () {
    const { open } = this.state
    this.setState({
      open: !open
    })
  }

  editableItemIds () {
    const { items = [] } = this.props
    return items
      .filter(item => item.hasOwnProperty('id') && item.editable)
      .map(item => item.id)
  }

  select (id) {
    const editableItemIds = this.editableItemIds()
    if (-1 !== editableItemIds.indexOf(id)) {
      const { items } = this.props
      const item = items.find(testItem => id === testItem.id)
      const { selected } = this.state
      if (item && -1 === selected.indexOf(id)) {
        selected.push(id)
        this.setState({ selected })
      }
    }
  }

  unselect (id) {
    const { selected } = this.state
    while (-1 !== selected.indexOf(id)) {
      selected.splice(selected.indexOf(id), 1)
    }
    this.setState({ selected })
  }

  selectAll (ev) {
    if (ev && ev.preventDefault) ev.preventDefault()
    const selected = this.editableItemIds()
    this.setState({ selected })
  }

  selectNone (ev) {
    if (ev && ev.preventDefault) ev.preventDefault()
    this.setState({ selected: [] })
  }

  handleInputChange = e => {
    const newState = {}
    newState[e.target.name] = e.target.value
    this.setState(newState)
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const { bulkAction, selected } = this.state
    const { editSelected, deleteSelected } = this.props
    if ('editSelected' === bulkAction) {
      editSelected(selected)
    } else if ('deleteSelected' === bulkAction) {
      deleteSelected(selected)
    }
  }

  render () {
    const { total = 0, title, type, interestRate, compoundingModel, items = [] } = this.props
    const { open, selected, bulkAction } = this.state
    const editableItemIds = this.editableItemIds()
    const editableItemsAvailable = editableItemIds.length > 0
    const allSelected = editableItemsAvailable && selected.length === editableItemIds.length
    const noneSelected = 0 === selected.length
    const isOpen = open ? ' is-open' : ''
    const isTypeAllItems = 'ALL_ITEMS' === type ? ' is-type-all-items' : ''
    const bulkActionCanBeSubmitted  = !!bulkAction && !noneSelected
    const compoundingModelLabel = CompoundingModels.ANNUALLY === compoundingModel
      ? 'annually' : 'monthly'
    return (
      <div className={`ItemList${isOpen}`}>
        <div className="ItemList-header" onClick={this.toggleList}>
          <h4 className={`ItemList-title${isTypeAllItems}`}>
            <span className="ItemList-header-open-indicator">{open ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}</span>
            {title}
          </h4>
          <p className={`ItemList-total${isTypeAllItems}`}>
            {presentDollarAmount(total)}
          </p>
        </div>
        {open &&
          <div className="ItemList-main">
            {('INTEREST_DUE' === type || 'ALL_ITEMS' === type) &&
              <div className="ItemList-description">
                <p><em>Interest due</em> items are calculated at a {interestRate * 100}% interest rate compounded {compoundingModelLabel}.</p>
              </div>
            }
            {editableItemsAvailable &&
              <form className="ItemList-bulk-actions" onSubmit={this.handleFormSubmit}>
                <Grid2Columns>
                  <div className="AppInput">
                    <select name="bulkAction" value={bulkAction} onChange={this.handleInputChange}>
                      <option value="">- Select Bulk Action -</option>
                      <option value="editSelected">Edit Selected</option>
                      <option value="deleteSelected">Delete Selected</option>
                    </select>
                  </div>
                  <div className="AppSubmit">
                    <button type="submit" disabled={!bulkActionCanBeSubmitted}>Apply Bulk Action</button>
                  </div>
                </Grid2Columns>
              </form>
            }
            <div className="ItemList-items-header">
              <p className="ItemList-items-header-select">
                {editableItemsAvailable && allSelected &&
                  <a className="ItemList-item-select"
                    href="#select-none"
                    title="Select none"
                    onClick={this.selectNone}>
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </a>
                }
                {editableItemsAvailable && !allSelected &&
                  <a className="ItemList-item-select"
                    href="#select-all"
                    title="Select all"
                    onClick={this.selectAll}>
                    <FontAwesomeIcon icon={faSquare} />
                  </a>
                }
              </p>
              <p className="ItemList-items-header-type-label">Type</p>
              <p className="ItemList-items-header-date">Date</p>
              <p className="ItemList-items-header-amount">Amount</p>
              <p className="ItemList-items-header-actions">Actions</p>
            </div>
            <div className="ItemList-items">
              {items.map((item, ind) => {
                const itemSelected = -1 !== selected.indexOf(item.id)
                const { editable = false } = item
                const className = `ItemList-item`
                  + `${'PAYMENT_MADE' === item.type ? ' is-negative' : ''}`
                  + `${editable ? ' is-editable' : ''}`
                  + `${itemSelected ? ' is-selected' : ''}`
                return (
                  <div
                    key={ind}
                    className={className}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      itemSelected ? this.unselect(item.id) : this.select(item.id)
                  }}
                >
                    <p>
                      {editable && !itemSelected &&
                        <a className="ItemList-item-select"
                          href='#select-item'
                          title='Select item'
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.select(item.id)
                          }}>
                          <FontAwesomeIcon icon={faSquare} />
                        </a>
                      }
                      {editable && itemSelected &&
                        <a className="ItemList-item-select"
                          href='#unselect-item'
                          title='Unselect item'
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.unselect(item.id)
                          }}>
                          <FontAwesomeIcon icon={faCheckSquare} />
                        </a>
                      }
                    </p>
                    <p>
                      {item.title}
                    </p>
                    <p>{presentMoment(item.date)}</p>
                    <p className="ItemList-item-amount">
                      {'PAYMENT_MADE' === item.type && '-'}{presentDollarAmount(item.amount)}
                    </p>
                    <p>
                      {editable &&
                        <a className="ItemList-item-action"
                          href='#edit-item'
                          title='Edit item'
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.props.editItem(item.id)
                          }}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </a>
                      }
                      {'' !== item.comments &&
                        <a className="ItemList-item-action"
                          href='#item-comments'
                          title='Item comments'
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.props.editItem(item.id)
                            //this.props.showComments(item.id)
                          }}>
                          <FontAwesomeIcon icon={faStickyNote} />
                        </a>
                      }
                      {editable &&
                        <a className="ItemList-item-action"
                          href='#delete-item'
                          title='Delete item'
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            const { deleteItem } = this.props
                            deleteItem(item.id)
                          }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      }
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default ItemListComponent
