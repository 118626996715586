/*
 * browser support functions
 */

const supportsInput = (type) => {
  const input = document.createElement('input')
  input.setAttribute('type', type)
  return type === input.type
}

export const supportsDateInput = supportsInput('date')
