// Compound Interest Calculation formula
// A = P * ( (1 + r /n ) ^ nt ) - P
// A : interestDueAmount
// P : principal (currentBalance)
// r : interestRate
// n : numberOfTimesInterestIsCompoundedPerYear
// t : number of years amount is accruing interest
// nt : compounding periods
export const calculateCompoundInterest = (principal, daysOfInterest, interestRate, numberOfTimesInterestIsCompoundedPerYear) => {
  const daysInYear = 365.25
  const compoundingPeriods = daysOfInterest * numberOfTimesInterestIsCompoundedPerYear / daysInYear
  return principal * Math.pow(1 + interestRate / numberOfTimesInterestIsCompoundedPerYear, compoundingPeriods) - principal
}
