import React, { Component } from 'react'
import { default as Content } from '../../store/pages/error'
import Header from '../Header'
import ContentBlock from '../ContentBlock'

class Error extends Component {
  render () {
    return (
      <div>
        <Header />
        <ContentBlock
          title={Content["content-block"]["title"]}
        />
      </div>
    )
  }
}

export default Error
