export const monthOptions = [
  {
    label: "January",
    value: 0
  },
  {
    label: "February",
    value: 1
  },
  {
    label: "March",
    value: 2
  },
  {
    label: "April",
    value: 3
  },
  {
    label: "May",
    value: 4
  },
  {
    label: "June",
    value: 5
  },
  {
    label: "July",
    value: 6
  },
  {
    label: "August",
    value: 7
  },
  {
    label: "September",
    value: 8
  },
  {
    label: "October",
    value: 9
  },
  {
    label: "November",
    value: 10
  },
  {
    label: "December",
    value: 11
  }
]

export const yearOptions = (() => {
  const endYear = (new Date()).getFullYear()
  const numberOfYearsAvailable = 30
  let i = 0
  let options = []
  while (i < numberOfYearsAvailable) {
    options.push({
      label: endYear - i,
      value: endYear - i
    })
    i++
  }
  return options
})()

export const dayOfMonthOptions = (() => {
  const options = [
    {
      label: "First day of the month",
      value: 1
    }
  ]
  let i = 1
  while (++i <= 28 ) {
    options.push({ label: i, value: i})
  }
  options.push({
    label: "Last day of the month",
    value: "LAST"
  })
  return options
})()

