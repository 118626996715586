import React, { Component } from 'react'
import Footer from './components/Footer'
import IndexRouter from './containers/routers/Index'
import ModalSelector from './containers/modals/ModalSelector'
import './App.css'

class App extends Component {
  render () {
    return (
      <div className="App">
        <IndexRouter />
        <Footer />
        <ModalSelector />
      </div>
    )
  }
}

export default App
