import React from 'react'
import { Transition } from 'react-transition-group'

const duration = 350
const defaultStyle = {
  opacity: 0,
  transition: `opacity ${duration}ms ease-out`
}
const transitionStyles = {
  entering: {opacity: 0},
  entered: {opacity: 1},
  exiting: {opacity: 0},
  exited: {opacity: 0}
}

const Fade = props => (
  <Transition timeout={duration} unmountOnExit={true} {...props}>
    {(state) => (
      <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
        {props.children}
      </div>
    )}
  </Transition>
)

export default Fade
