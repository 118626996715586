import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import './index.css'
import App from './App'
import { unregister } from './registerServiceWorker'
import { updateCurrentDateISO } from './store/actions'
import interestCalculculatorApp, { initialState } from './store/reducers'

const currentDateISO = () => (new Date()).toISOString()
const store = createStore(
  interestCalculculatorApp,
  initialState(currentDateISO()),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
setInterval(() => store.dispatch(updateCurrentDateISO(currentDateISO())), 60000)
if ('development' === process.env.NODE_ENV) {
  store.subscribe(() => console.log(store.getState()))
}
render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

unregister()
