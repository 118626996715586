import React, { Component } from 'react'
import { connect } from 'react-redux'
import { default as Content } from '../../store/pages/free-spousal-support-interest-calculator'
import Header from '../../components/Header'
import ContentWithSidebar from '../../components/layouts/ContentWithSidebar'
import ContentBlock from '../../components/ContentBlock'
import ArticlePreview from '../../components/ArticlePreview'
import InterestCalculatorComponent from './InterestCalculatorComponent'
import { SPOUSAL_SUPPORT } from '../../constants/InterestCalculatorTypes'
import { mapDispatchToProps } from './PageChildSupport'

class PageSpousalSupport extends Component {
  constructor (props) {
    super(props)
    const { actions } = props
    const { configureInterestCalculator } = actions
    const settings = { type: SPOUSAL_SUPPORT }
    configureInterestCalculator(settings)
  }
  render () {
    const { dispatch, introDispatch } = this.props
    const intro = Content['intro']['content-block']
    intro['dispatch'] = introDispatch
    return (
      <div>
        <Header />
        <ContentWithSidebar has-background={true}>
          <ContentBlock 
            title={Content["content-block"]["title"]}
            body={Content["content-block"]["body"]}
            cta-buttons={Content["content-block"]["cta-buttons"]}
            dispatch={dispatch}
          />
          <ArticlePreview
            title={Content["article-preview"]["title"]}
            category={Content["article-preview"]["category"]}
            excerpt={Content["article-preview"]["excerpt"]}
            url={Content['article-preview']['url']}
            image={Content["article-preview"]["image"]} />
        </ContentWithSidebar>
        <InterestCalculatorComponent
          intro={intro}
          dispatch={introDispatch}
        />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(PageSpousalSupport)
