import moment from 'moment'
import * as freq from '../constants/FrequencyTypes'

/* errors */
export class FrequencyRequiredError extends Error {}
export class InvalidFrequencyError extends Error {}

export const createSchedule = (options = {}) => {
  if (!options.hasOwnProperty('frequency')) throw new FrequencyRequiredError()
  if ('undefined' === typeof freq[options.frequency]) throw new InvalidFrequencyError()
  const schedule = []
  let currentDate, endDate
  if (freq.SINGLE === options.frequency) {
    schedule.push(moment(options.startDate).startOf('day'))
  } else if (freq.WEEKLY === options.frequency) {
    currentDate = moment(options.startDate).startOf('day')
    while (currentDate.isSameOrBefore(options.endDate)) {
      schedule.push(moment(currentDate))
      currentDate.add(1, 'week')
    }
  } else if (freq.BIWEEKLY === options.frequency) {
    currentDate = moment(options.startDate)
    while (currentDate.isSameOrBefore(options.endDate)) {
      schedule.push(moment(currentDate))
      currentDate.add(2, 'week')
    }
  } else if (freq.SEMIMONTHLY === options.frequency) {
    // handle all variations of use of LAST option for day of month options
    if ('LAST' === options.dayOfMonth1 && 'LAST' === options.dayOfMonth2) {
      currentDate = moment([options.startYear, options.startMonth]).endOf('month').startOf('day')
      endDate = moment([options.endYear, options.endMonth]).endOf('month').startOf('day')
      while (currentDate.isSameOrBefore(endDate)) {
        schedule.push(moment(currentDate).endOf('month').startOf('day'))
        schedule.push(moment(currentDate).endOf('month').startOf('day'))
        currentDate.add(1, 'month')
      }
    } else if ('LAST' === options.dayOfMonth1) {
      currentDate = moment([options.startYear, options.startMonth, options.dayOfMonth2])
      endDate = moment([options.endYear, options.endMonth]).endOf('month').startOf('day')
      while (currentDate.isSameOrBefore(endDate)) {
        schedule.push(
          moment([
            Number(currentDate.year()),
            Number(currentDate.month()),
            moment(currentDate).endOf('month').startOf('day').date()
          ])
        )
        schedule.push(moment([
          Number(currentDate.year()),
          Number(currentDate.month()),
          Number(options.dayOfMonth2)
        ]).startOf('day'))
        currentDate.add(1, 'month')
      }
    } else if ('LAST' === options.dayOfMonth2) {
      currentDate = moment([options.startYear, options.startMonth, options.dayOfMonth1]).startOf('day')
      endDate = moment([options.endYear, options.endMonth]).endOf('month').startOf('day')
      while (currentDate.isSameOrBefore(endDate)) {
        schedule.push(moment([
          Number(currentDate.year()),
          Number(currentDate.month()),
          Number(options.dayOfMonth1)
        ]).startOf('day'))
        schedule.push(
          moment([
            Number(currentDate.year()),
            Number(currentDate.month()),
            moment(currentDate).endOf('month').startOf('day').date()
          ])
        )
        currentDate.add(1, 'month')
      }
    } else {
      // handle day of month options when LAST option is not used
      currentDate = moment([
        options.startYear,
        options.startMonth,
        Math.min(Number(options.dayOfMonth1), Number(options.dayOfMonth2))
      ])
      endDate = moment([
        options.endYear,
        options.endMonth,
        Math.max(Number(options.dayOfMonth1), Number(options.dayOfMonth2))
      ])
      while (currentDate.isSameOrBefore(endDate)) {
        schedule.push(moment([
          currentDate.year(), currentDate.month(),
          Number(options.dayOfMonth1)
        ]))
        schedule.push(moment([
          currentDate.year(), currentDate.month(),
          Number(options.dayOfMonth2)
        ]))
        currentDate.add(1, 'month')
      }
    }
  } else if (freq.MONTHLY === options.frequency) {
    if ('LAST' === options.monthlyDayOfMonth) {
      currentDate = moment([options.startYear, options.startMonth]).endOf('month').startOf('day')
      endDate = moment([options.endYear, options.endMonth]).endOf('month').startOf('day')
    } else {
      currentDate = moment([options.startYear, options.startMonth, options.monthlyDayOfMonth]).startOf('day')
      endDate = moment([options.endYear, options.endMonth, options.monthlyDayOfMonth]).startOf('day')
    }
    while (currentDate.isSameOrBefore(endDate)) {
      schedule.push(
        moment([currentDate.year(),currentDate.month(),currentDate.date()])
          .startOf('day')
      )
      currentDate.add(1, 'month')
      if ('LAST' === options.monthlyDayOfMonth) currentDate.endOf('month').startOf('day')
    }
  }
  return schedule
}
