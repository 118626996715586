import React from 'react'
import { withRouter } from 'react-router'
import MatomoTracker from '../../node-modules-copy/matomo-tracker'

const matomo = new MatomoTracker(1, 'https://legalthunderapps.innocraft.cloud/piwik.php')
class Tracker extends React.Component {
  render () {
    const { location } = this.props
    matomo.track(location.pathname)
    return null
  }
}

export default withRouter(Tracker)
