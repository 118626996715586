import * as freq from '../constants/FrequencyTypes'

export const roundToCents = (amount) => Math.round( 100 * amount ) / 100

export const convertMonthlyAmount = (frequency, amount) => {
  const yearlyAmount = 12 * amount
  const yearlyPayments = freq.WEEKLY === frequency ? 52 :
    freq.BIWEEKLY === frequency ? 26 :
    freq.SEMIMONTHLY === frequency ? 24 :
    freq.MONTHLY === frequency ? 12
    : 12 // default 12 for single payment, since monthly payment amount is input
  return roundToCents(yearlyAmount / yearlyPayments)
}
