import React, { Component } from 'react'
import './CTAButtonGroup.css'
import CTAButton from '../buttons/CTAButton'

class CTAButtonGroup extends Component {
  renderCTAButtons () {
    return 
  }

  render () {
    return (
      <div className="CTAButtonGroup">
        {this.props["cta-buttons"].map((ctaButton, ind) => <CTAButton key={ind} text={ctaButton.text} callback={ctaButton.callback} dark={ctaButton.dark} payload={ctaButton.payload}/>)}
      </div>
    )
  }
}

export default CTAButtonGroup
