import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import * as freq from '../../constants/FrequencyTypes'
import * as calculatorTypes from '../../constants/InterestCalculatorTypes'
import { addPaymentsDue, addPaymentsMade } from '../../store/actions'
import ModalWrapper from '../../components/modals/ModalWrapper'
import Grid2Columns from '../../components/layouts/Grid2Columns'
import { monthOptions, yearOptions, dayOfMonthOptions } from '../../helpers/options'
import { errorMessagesArrayToObject } from '../../helpers/reducers'
import { presentMomentAsDateInputValue, dateInputFormat } from '../../helpers/presenters'
import { supportsDateInput } from '../../helpers/browser-support'
import './PaymentGroupForm.css'

// todo: remove Months over which retroactive payments are due field
// todo: bring back add retroactiveMonthlyAmount 
let validate = (paymentGroup) => {
  //todo: add validation for retroactiveMonthlyAmount (cannot be more months than timespan of payments due
  const errors = []
  if ('undefined' === typeof paymentGroup.amount || '' === paymentGroup.amount) {
    errors.push(['amount', 'Required'])
  } else {
    if (0 >= paymentGroup.amount) errors.push(['amount', 'Must be greater than $0'])
  }
  switch (paymentGroup.frequency) {
  case freq.SINGLE:
    if (!paymentGroup.date || !moment.isMoment(paymentGroup.date)) errors.push(['date', 'Must be a valid date'])
    break

  case freq.WEEKLY:
  case freq.BIWEEKLY:
    if (!paymentGroup.startDate || !moment.isMoment(paymentGroup.startDate)) errors.push(['startDate', 'Must be a valid date'])
    if (!paymentGroup.endDate || !moment.isMoment(paymentGroup.endDate)) errors.push(['endDate', 'Must be a valid date'])
    if (moment.isMoment(paymentGroup.startDate)
      && moment.isMoment(paymentGroup.endDate)
      && paymentGroup.endDate.isBefore(paymentGroup.startDate)) errors.push(['endDate', 'Must be after start date'])
    break

  case freq.SEMIMONTHLY:
    if ('' === paymentGroup.semimonthlyDayOfMonth) errors.push(['semimonthlyDayOfMonth', 'Required'])
    if ('' === paymentGroup.semimonthlyDayOfMonth2) errors.push(['semimonthlyDayOfMonth2', 'Required'])
    break

  case freq.MONTHLY:
    if ('' === paymentGroup.monthlyDayOfMonth) errors.push(['monthlyDayOfMonth', 'Required'])
    break

  default:
    errors.push(['frequency', 'Required'])
    break
  }
  if (freq.SEMIMONTHLY === paymentGroup.frequency || freq.MONTHLY === paymentGroup.frequency) {
    if ('' === paymentGroup.startMonth ) errors.push(['startMonth', 'Required'])
    if ('' === paymentGroup.startYear) errors.push(['startYear', 'Required'])
    if ('' === paymentGroup.endMonth ) errors.push(['endMonth', 'Required'])
    if ('' === paymentGroup.endYear) errors.push(['endYear', 'Required'])
    if ('' !== paymentGroup.startMonth && '' !== paymentGroup.startYear && '' !== paymentGroup.endMonth && '' !== paymentGroup.endYear) {
      if ((paymentGroup.startYear === paymentGroup.endYear
        && paymentGroup.startMonth > paymentGroup.endMonth)) errors.push(['endMonth', 'Must be on or after first month payment is due'])
      if (paymentGroup.endYear < paymentGroup.startYear) errors.push(['endYear', 'Must be on or after first year payment is due'])
    }
  }
  return errors
}


export class PaymentGroupFormComponent extends Component {
  constructor (props) {
    super(props)
    const { type, supportType } = props
    this.state = this.initialState(type, supportType)
  }
  initialState (type, supportType) {
    return {
      type: type,
      id: null,
      title: 'PAYMENT_GROUP_DUE' === type ? 'Payment Due' : 'Payment Made',
      amount: '',
      frequency: freq.SINGLE,
      // retroactive options
      retroactiveTotalAmount: '',
      retroactiveMonthlyAmount: '',
      retroactiveInterestModel: '',
      supportOrderEnteredDate: null,
      // single options
      date: null,
      dateTextInput: '',
      // weekly & biweekly options
      startDate: null,
      startDateTextInput: '',
      endDate: null,
      endDateTextInput: '',
      // semimonthly options
      semimonthlyDayOfMonth: '',
      semimonthlyDayOfMonth2: '',
      // monthly options
      monthlyDayOfMonth: '',
      // semimonthly & monthly options
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      comments: '',
      errors: [],
      // dynamic text in form
      form: {
        title: 'PAYMENT_GROUP_DUE' === type ? 'Enter Payments Due' : 'Enter Payments Made',
        paymentType: 'PAYMENT_GROUP_DUE' === type ? 'due' : 'made',
        amountDueLabel: calculatorTypes.CHILD_SUPPORT === supportType
          ? 'Current monthly child support due'
          : 'Current monthly maintenance (spousal/partner support) due',
        retroactiveTotalAmountLabel: calculatorTypes.CHILD_SUPPORT === supportType
          ? 'Total retroactive child support due'
          : 'Total retroactive maintenance  (spousal/partner support) due',
        retroactiveTotalAmountLabelNote: calculatorTypes.CHILD_SUPPORT === supportType
          ? <span className="PaymentGroupForm-label-note">If any retroactive child support is due, enter the <em>total amount</em> accrued as of the date of the support order.</span>
          : <span className="PaymentGroupForm-label-note">If any retroactive maintenance (spousal/partner support) is due, enter the <em>total amount</em> accrued as of the date of the support order.</span>
      }
    }
  }

  dateInputValue = () => {
    return presentMomentAsDateInputValue(this.state.date)
  }

  startDateInputValue = () => {
    return presentMomentAsDateInputValue(this.state.startDate)
  }

  endDateInputValue = () => {
    return presentMomentAsDateInputValue(this.state.endDate)
  }

  supportOrderEnteredDateInputValue = () => {
    return presentMomentAsDateInputValue(this.state.supportOrderEnteredDate)
  }

  handleInputChange = (e) => {
    const target = e.target
    const name = target.name
    const type = target.type
    const rawValue = target.value
    const newState = {}
    const paymentGroup = this.paymentGroupFromState()
    let value
    if ('startMonth' === name
      || 'startYear' === name
      || 'endMonth' === name
      || 'endYear' === name) {
      value = '' === rawValue ? rawValue : Number(rawValue)
      newState[name] = paymentGroup[name] = value
    } else if ('number' === type) {
      value = '' === rawValue ? rawValue : Number(rawValue)
      newState[name] = paymentGroup[name] = value
    } else if ('date' === type) {
      value = rawValue ? moment(rawValue) : null
      newState[name] = paymentGroup[name] = value
    } else if ('dateTextInput' === name) {
      const parsedRawValue = moment(rawValue, dateInputFormat, true)
      value = parsedRawValue.isValid() ? parsedRawValue : null
      newState[name] = rawValue
      newState['date'] = paymentGroup['date'] = value
    } else if ('startDateTextInput' === name) {
      const parsedRawValue = moment(rawValue, dateInputFormat, true)
      value = parsedRawValue.isValid() ? parsedRawValue : null
      newState[name] = rawValue
      newState['startDate'] = paymentGroup['startDate'] = value
    } else if ('endDateTextInput' === name) {
      const parsedRawValue = moment(rawValue, dateInputFormat, true)
      value = parsedRawValue.isValid() ? parsedRawValue : null
      newState[name] = rawValue
      newState['endDate'] = paymentGroup['endDate'] = value
    } else {
      value = rawValue
      newState[name] = paymentGroup[name] = value
    }
    if (this.state.showErrors) newState['errors'] = validate(paymentGroup)
    this.setState(newState)
  }

  paymentGroupFromState () {
    return {
      type: this.state.type,
      id: this.state.id,
      title: this.state.title,
      amount: this.state.amount,
      frequency: this.state.frequency,
      // retroactive options
      retroactiveTotalAmount: this.state.retroactiveTotalAmount,
      retroactiveMonthlyAmount: this.state.retroactiveMonthlyAmount,
      retroactiveInterestModel: this.state.retroactiveInterestModel,
      supportOrderEnteredDate: this.state.supportOrderEnteredDate,
      // single options
      date: this.state.date,
      // weekly & biweekly options
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      // semimonthly options
      semimonthlyDayOfMonth: this.state.semimonthlyDayOfMonth,
      semimonthlyDayOfMonth2: this.state.semimonthlyDayOfMonth2,
      // monthly options
      monthlyDayOfMonth: this.state.monthlyDayOfMonth,
      // semimonthly & monthly options
      startMonth: this.state.startMonth,
      startYear: this.state.startYear,
      endMonth: this.state.endMonth,
      endYear: this.state.endYear,
      comments: this.state.comments,
    }
  }

  validateForm () {
    const errors = validate(this.paymentGroupFromState())
    this.setState({ errors })
    return errors
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    const errors = this.validateForm()
    if (errors.length) {
      this.setState({showErrors: true})
    } else if ('PAYMENT_GROUP_DUE' === this.state.type) {
      this.props.dispatch(addPaymentsDue(this.paymentGroupFromState()))
    } else if ('PAYMENT_GROUP_MADE' === this.state.type) {
      this.props.dispatch(addPaymentsMade(this.paymentGroupFromState()))
    }
  }

  render () {
    const { amount, frequency, errors, showErrors, retroactiveTotalAmount,
      retroactiveInterestModel, form, type } = this.state
    const { title, paymentType, amountDueLabel, retroactiveTotalAmountLabel, retroactiveTotalAmountLabelNote }
      = form
    const errorMessages = errorMessagesArrayToObject(errors)
    const showSingleOptions = freq.SINGLE === frequency
    const showWeeklyAndBiweeklyOptions = freq.WEEKLY === frequency
      || freq.BIWEEKLY === frequency
    const showSemimonthlyOptions = freq.SEMIMONTHLY === frequency
    const showMonthlyOptions = freq.MONTHLY === frequency
    // todo: don't display retroactive interest model options if frequency is SINGLE
    return (
      <ModalWrapper {...this.props}>
        <form className="PaymentGroupForm" onSubmit={this.handleFormSubmit}>

          <h3 className="AppFormTitle">{title}</h3>

          <Grid2Columns>
            <div>
              <div className={`AppInput${ showErrors && errorMessages['frequency'] ? ' has-error' : ''}`}>
                <label htmlFor="frequency">Frequency of payments {paymentType}</label>
                <label className="AppInput-radio-label">One time
                  <input name="frequency" value={freq.SINGLE} type="radio" checked={freq.SINGLE === frequency} onChange={this.handleInputChange} />
                  <span className="AppInput-radio-checkmark" />
                </label>
                <label className="AppInput-radio-label">Weekly
                  <input name="frequency" value={freq.WEEKLY} type="radio" checked={freq.WEEKLY === frequency} onChange={this.handleInputChange} />
                  <span className="AppInput-radio-checkmark" />
                </label>
                <label className="AppInput-radio-label">Bi-weekly (every two weeks)
                  <input name="frequency" value={freq.BIWEEKLY} type="radio" checked={freq.BIWEEKLY === frequency} onChange={this.handleInputChange} />
                  <span className="AppInput-radio-checkmark" />
                </label>
                <label className="AppInput-radio-label">Twice a month
                  <input name="frequency" value={freq.SEMIMONTHLY} type="radio" checked={freq.SEMIMONTHLY === frequency} onChange={this.handleInputChange} />
                  <span className="AppInput-radio-checkmark" />
                </label>
                <label className="AppInput-radio-label">Monthly
                  <input name="frequency" value={freq.MONTHLY} type="radio" checked={freq.MONTHLY === frequency} onChange={this.handleInputChange} />
                  <span className="AppInput-radio-checkmark" />
                </label>
                {showErrors && errorMessages['frequency'] &&
                  <p className="AppInputError">{errorMessages['frequency']}</p>
                }
              </div>

              <div className={`AppInput${ showErrors && errorMessages['amount'] ? ' has-error' : ''}`}>
                {'PAYMENT_GROUP_DUE' === type &&
                  <label htmlFor="amount">{amountDueLabel}<br /><span className="PaymentGroupForm-label-note">Do not include payments towards arrears or retroactive support.</span></label>
                }
                {'PAYMENT_GROUP_MADE' === type &&
                  <label htmlFor="amount">Monthly amount paid</label>
                }
                <input id="amount" type="number" value={amount} step=".01" min="0.01" placeholder="Enter amount..." name="amount" onChange={this.handleInputChange} />
                {showErrors && errorMessages['amount'] &&
                  <p className="AppInputError">{errorMessages['amount']}</p>
                }
              </div>

              {'PAYMENT_GROUP_DUE' === type &&
                <div>
                  <div className={`AppInput${ showErrors && errorMessages['retroactiveTotalAmount'] ? ' has-error' : ''}`}>
                    <label htmlFor="retroactiveTotalAmount">{retroactiveTotalAmountLabel}<br />{retroactiveTotalAmountLabelNote}</label>
                    <input id="retroactiveTotalAmount" type="number" value={retroactiveTotalAmount} step=".01" min="0" placeholder="Enter amount..." name="retroactiveTotalAmount" onChange={this.handleInputChange} />
                    {showErrors && errorMessages['retroactiveTotalAmount'] &&
                      <p className="AppInputError">{errorMessages['retroactiveTotalAmount']}</p>
                    }
                  </div>

                  {0 < this.state.retroactiveTotalAmount &&
                    <div>
                      <div className={`AppInput${ showErrors && errorMessages['retroactiveInterestModel'] ? ' has-error' : ''}`}>
                        <label htmlFor="retroactiveInterestModel">Retroactive support interest model</label>
                        <label className="AppInput-radio-label">Apply interest to total retroactive support as lump sum
                          <input name="retroactiveInterestModel" value="LUMP_SUM" type="radio" checked={'LUMP_SUM' === retroactiveInterestModel} onChange={this.handleInputChange} />
                          <span className="AppInput-radio-checkmark" />
                        </label>
                        <label className="AppInput-radio-label">Apply interest to retroactive support payments over time
                          <input name="retroactiveInterestModel" value="OVER_TIME" type="radio" checked={'OVER_TIME' === retroactiveInterestModel} onChange={this.handleInputChange} />
                          <span className="AppInput-radio-checkmark" />
                        </label>
                      </div>
                      {'LUMP_SUM' === retroactiveInterestModel &&
                        <div className={`AppInput${ showErrors && errorMessages['supportOrderEnteredDate'] ? ' has-error' : ''}`}>
                          <label htmlFor="supportOrderEnteredDate">
                            Date support order was entered
                            <br /><span className="PaymentGroupForm-label-note">{dateInputFormat}</span>
                          </label>
                          <input id="supportOrderEnteredDate" type="date" name="supportOrderEnteredDate" value={this.supportOrderEnteredDateInputValue()} onChange={this.handleInputChange} />
                          {showErrors && errorMessages['supportOrderEnteredDate'] &&
                            <p className="AppInputError">{errorMessages['supportOrderEnteredDate']}</p>
                          }
                        </div>
                      }
                      {'OVER_TIME' === retroactiveInterestModel &&
                        <div className={`AppInput${ showErrors && errorMessages['retroactiveMonthlyAmount'] ? ' has-error' : ''}`}>
                          <label htmlFor="retroactiveMonthlyAmount">Monthly retroactive support due<br /><span className="PaymentGroupForm-label-note">Enter the total monthly obligation payment towards retroactive support due.</span></label>
                          <input id="retroactiveMonthlyAmount" type="number" value={this.state.retroactiveMonthlyAmount} step="1" min="1" placeholder="Enter amount..." name="retroactiveMonthlyAmount" onChange={this.handleInputChange} />
                          {showErrors && errorMessages['retroactiveMonthlyAmount'] &&
                            <p className="AppInputError">{errorMessages['retroactiveMonthlyAmount']}</p>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              }

              <div className={`AppInput${ showErrors && errorMessages['comments'] ? ' has-error' : ''}`}>
                <label htmlFor="comments">Comments</label>
                <textarea id="comments" name="comments" value={this.state.comments} placeholder="Enter comments..." rows="3" maxLength="500" onChange={this.handleInputChange} />
                {showErrors && errorMessages['comments'] &&
                  <p className="AppInputError">{errorMessages['comments']}</p>
                }
              </div>
            </div>

            <div>
              {showSingleOptions &&
                <div>
                  <div className={`AppInput${ showErrors && errorMessages['date'] ? ' has-error' : ''}`}>
                    <label htmlFor="startDate">
                      Date of payment {paymentType}
                      <br /><span className="PaymentGroupForm-label-note">{dateInputFormat}</span>
                  </label>
                    {supportsDateInput &&
                      <input id="date" type="date" name="date" value={this.dateInputValue()} onChange={this.handleInputChange} />
                    }
                    {!supportsDateInput &&
                      <input id="date" type="text" name="dateTextInput" value={this.state.dateTextInput} onChange={this.handleInputChange} maxLength="10" />
                    }
                    {showErrors && errorMessages['date'] &&
                      <p className="AppInputError">{errorMessages['date']}</p>
                    }
                  </div>
                </div>
              }

              {showWeeklyAndBiweeklyOptions &&
                <div>
                  <div className={`AppInput${ showErrors && errorMessages['startDate'] ? ' has-error' : ''}`}>
                    <label htmlFor="startDate">
                      Date of first payment {paymentType}
                      <br /><span className="PaymentGroupForm-label-note">{dateInputFormat}</span>
                  </label>
                    {supportsDateInput &&
                      <input id="startDate" type="date" name="startDate" value={this.startDateInputValue()} onChange={this.handleInputChange} />
                    }
                    {!supportsDateInput &&
                      <input id="startDate" type="text" name="startDateTextInput" value={this.state.startDateTextInput} onChange={this.handleInputChange} maxLength="10" />
                    }
                    {showErrors && errorMessages['startDate'] &&
                      <p className="AppInputError">{errorMessages['startDate']}</p>
                    }
                  </div>

                  <div className={`AppInput${ showErrors && errorMessages['endDate'] ? ' has-error' : ''}`}>
                    <label htmlFor="endDate">
                      Date of last payment {paymentType}
                      <br /><span className="PaymentGroupForm-label-note">{dateInputFormat}</span>
                    </label>
                    {supportsDateInput &&
                      <input id="endDate" type="date" name="endDate" value={this.endDateInputValue()} onChange={this.handleInputChange} />
                    }
                    {!supportsDateInput &&
                      <input id="endDate" type="text" name="endDateTextInput" value={this.state.endDateTextInput} onChange={this.handleInputChange} maxLength="10" />
                    }
                    {showErrors && errorMessages['endDate'] &&
                      <p className="AppInputError">{errorMessages['endDate']}</p>
                    }
                  </div>
                </div>
              }

              {showSemimonthlyOptions &&
                <div>
                  <div className={`AppInput${ showErrors && errorMessages['semimonthlyDayOfMonth'] ? ' has-error' : ''}`}>
                    <label htmlFor="semimonthlyDayOfMonth">Day of month of first monthly payment {paymentType}</label>
                    <select id="semimonthlyDayOfMonth" name="semimonthlyDayOfMonth" value={this.state.semimonthlyDayOfMonth} onChange={this.handleInputChange}>
                      <option value="" key="blank"> - Please select - </option>
                      {dayOfMonthOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                    </select>
                    {showErrors && errorMessages['semimonthlyDayOfMonth'] &&
                      <p className="AppInputError">{errorMessages['semimonthlyDayOfMonth']}</p>
                    }
                  </div>
                  <div className={`AppInput${ showErrors && errorMessages['semimonthlyDayOfMonth2'] ? ' has-error' : ''}`}>
                    <label htmlFor="semimonthlyDayOfMonth2">Day of month of second monthly payment {paymentType}</label>
                    <select id="semimonthlyDayOfMonth2" name="semimonthlyDayOfMonth2" value={this.state.semimonthlyDayOfMonth2} onChange={this.handleInputChange}>
                      <option value="" key="blank"> - Please select - </option>
                      {dayOfMonthOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                    </select>
                    {showErrors && errorMessages['semimonthlyDayOfMonth2'] &&
                      <p className="AppInputError">{errorMessages['semimonthlyDayOfMonth2']}</p>
                    }
                  </div>
                </div>
              }

              {showMonthlyOptions &&
                <div className={`AppInput${ showErrors && errorMessages['monthlyDayOfMonth'] ? ' has-error' : ''}`}>
                  {'PAYMENT_GROUP_DUE' === this.state.type &&
                    <label htmlFor="monthlyDayOfMonth">
                      Day of month of each payment {paymentType}
                      <br /><span className="PaymentGroupForm-label-note">Select last day of the month if no date is given.</span>
                    </label>
                  }
                  {'PAYMENT_GROUP_MADE' === this.state.type &&
                    <label htmlFor="monthlyDayOfMonth">
                      Day of month of each payment {paymentType}
                    </label>
                  }
                  <select id="monthlyDayOfMonth" name="monthlyDayOfMonth" value={this.state.monthlyDayOfMonth} onChange={this.handleInputChange}>
                    <option value="" key="blank"> - Please select - </option>
                    {dayOfMonthOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                  </select>
                  {showErrors && errorMessages['monthlyDayOfMonth'] &&
                    <p className="AppInputError">{errorMessages['monthlyDayOfMonth']}</p>
                  }
                </div>
              }

              {(showMonthlyOptions || showSemimonthlyOptions) &&
                <div>
                  <div className={`AppInput`}>
                    <label htmlFor="startMonth">Month of first payment {paymentType}</label>
                    <Grid2Columns>
                      <div className={`${showErrors && errorMessages['startMonth'] ? 'has-error' : ''}`}>
                        <select id="startMonth" name="startMonth" value={this.state.startMonth} onChange={this.handleInputChange}>
                          <option value="" key="blank"> - Please select - </option>
                          {monthOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                        </select>
                        {showErrors && errorMessages['startMonth'] &&
                          <p className="AppInputError">{errorMessages['startMonth']}</p>
                        }
                      </div>
                      <div className={`${showErrors && errorMessages['startYear'] ? 'has-error' : ''}`}>
                        <select id="startYear" name="startYear" value={this.state.startYear} onChange={this.handleInputChange}>
                          <option value="" key="blank"> - Please select - </option>
                          {yearOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                        </select>
                        {showErrors && errorMessages['startYear'] &&
                          <p className="AppInputError">{errorMessages['startYear']}</p>
                        }
                      </div>
                    </Grid2Columns>
                  </div>
                  <div className={`AppInput`}>
                    <label htmlFor="endMonth">Month of last payment {paymentType}</label>
                    <Grid2Columns>
                      <div className={`${showErrors && errorMessages['endMonth'] ? 'has-error' : ''}`}>
                        <select id="endMonth" name="endMonth" value={this.state.endMonth} onChange={this.handleInputChange}>
                          <option value="" key="blank"> - Please select - </option>
                          {monthOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                        </select>
                        {showErrors && errorMessages['endMonth'] &&
                          <p className="AppInputError">{errorMessages['endMonth']}</p>
                        }
                      </div>
                      <div className={`${showErrors && errorMessages['endYear'] ? 'has-error' : ''}`}>
                        <select id="endYear" name="endYear" value={this.state.endYear} onChange={this.handleInputChange}>
                          <option value="" key="blank"> - Please select - </option>
                          {yearOptions.map((opt, i) => <option value={opt.value} key={i}>{opt.label}</option>)}
                        </select>
                        {showErrors && errorMessages['endYear'] &&
                          <p className="AppInputError">{errorMessages['endYear']}</p>
                        }
                      </div>
                    </Grid2Columns>
                  </div>
                </div>
              }

            </div>
          </Grid2Columns>

          <Grid2Columns>
            <div className="AppSubmit">
              <button className="dark" type="submit">Submit</button>
            </div>
          </Grid2Columns>

        </form>
      </ModalWrapper>
    )
  }
}

const PaymentGroupForm = connect()(PaymentGroupFormComponent)
export default PaymentGroupForm
