import React, { Component } from 'react'
import './CTAButton.css'

class CTAButton extends Component {

  handleClick (e) {
    e.preventDefault()
    if (this.props.callback) this.props.callback()
  }

  render () {
    return (
      <button className={'CTAButton' + (this.props.dark ? ' dark' : '')} onClick={this.handleClick.bind(this)} >{this.props.text}</button>
    )
  }

}

export default CTAButton
