export function errorMessagesArrayToObject (errors) {
  let messages = {}
  errors.forEach((error) => messages[error[0]] = error[1])
  return messages
}

export function itemById (id, items) {
  return items.reduce((a, cv) => {
    return (id === cv.id) ? cv : a
  }, null)
}

export const maxId = (a, cv) => {
  return (a > cv.id) ? a : cv.id
}

export const sumAmounts = (a, cv) => a + cv.amount
