import React, { Component } from 'react'
import './ContentWithSidebar.css'

class ContentWithSidebar extends Component {
  className () {
    return "ContentWithSidebar" + (this.props['has-background']?' has-background':'')
  }
  render () {
    return (
      <div className={this.className()}>
        {this.props.children}
      </div>
    )
  }
}

export default ContentWithSidebar
