import React from 'react'
import { connect } from 'react-redux'
import Fade from '../../components/transitions/Fade'
import { closeModal as close } from '../../store/actions'
import PaymentGroupForm from '../interest-calculator/PaymentGroupForm'
import CalculatorItemForm from '../interest-calculator/CalculatorItemForm'
import CalculatorItemsForm from '../interest-calculator/CalculatorItemsForm'
import ConfirmAction from '../../components/modals/ConfirmAction'
import ConfirmDeleteItemForm from '../interest-calculator/ConfirmDeleteItemForm'
import ConfirmDeleteItemsForm from '../interest-calculator/ConfirmDeleteItemsForm'
import { itemById } from '../../helpers/reducers'

const mapStateToProps = state => {
  const {
    supportType,
    uiModalType,
    uiModalIn,
    uiLastAction,
    editingCalculatorItemId,
    editingCalculatorItemIds,
    deletingCalculatorItemIds,
    calculatorItems
  } = state
  const items = ('CONFIRM_DELETE_ITEMS' === uiModalType
    && deletingCalculatorItemIds && deletingCalculatorItemIds.length > 0)
      ? deletingCalculatorItemIds.map(id => itemById(id, calculatorItems)) : 
    ('EDIT_CALCULATOR_ITEMS' === uiModalType
    && editingCalculatorItemIds && editingCalculatorItemIds.length > 0)
      ? editingCalculatorItemIds : null
  return {
    supportType,
    modal: {
      type: uiModalType,
      in: uiModalIn
    },
    lastAction: uiLastAction,
    item: itemById(editingCalculatorItemId, calculatorItems),
    items
  }
}

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(close())
  }
}

const ModalSelectorComponent = props => (
  <Fade in={props.modal.in} {...props}>
    { 'ADD_PAYMENTS_DUE' === props.modal.type && <PaymentGroupForm type='PAYMENT_GROUP_DUE' {...props}/> }
    { 'ADD_PAYMENTS_MADE' === props.modal.type && <PaymentGroupForm type='PAYMENT_GROUP_MADE' {...props}/> }
    { 'EDIT_CALCULATOR_ITEM' === props.modal.type && props.item &&
      <CalculatorItemForm {...props}/> }
    { 'EDIT_CALCULATOR_ITEMS' === props.modal.type && props.items &&
      <CalculatorItemsForm {...props}/> }
    { 'CONFIRM_DELETE_ITEM' === props.modal.type && props.item &&
      <ConfirmDeleteItemForm {...props}/> }
    { 'CONFIRM_DELETE_ITEMS' === props.modal.type && props.items &&
      <ConfirmDeleteItemsForm {...props}/> }
    }

    { 'CONFIRM_ACTION' === props.modal.type && <ConfirmAction {...props}/> }
  </Fade>
)
const ModalSelector = connect(mapStateToProps, mapDispatchToProps)(ModalSelectorComponent)
export default ModalSelector
