import React, { Component } from 'react'
import ContentWithSidebar from '../../components/layouts/ContentWithSidebar'
import ContentBlock from '../../components/ContentBlock'
//import ArticlePreview from '../../../components/ArticlePreview'
import { PaymentsDueList, PaymentsMadeList, InterestAccruedList, AllItemsList, TotalWithoutInterestList } from './ItemsLists'



class InterestCalculatorComponent extends Component {
  render () {
    const { intro } = this.props;
    return (
      <main className='InterestCalculator' id='interest-calculator'>
        <ContentWithSidebar>
          <section>
            <ContentBlock {...intro} />
            <PaymentsDueList />
            <PaymentsMadeList />
            <TotalWithoutInterestList />
            <InterestAccruedList />
            <AllItemsList />
          </section>
          {/*<section><ArticlePreview {...this.props.article} /></section>*/}
        </ContentWithSidebar>
      </main>
    )
  }
}

export default InterestCalculatorComponent
