import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteCalculatorItem } from '../../store/actions'
import ModalWrapper from '../../components/modals/ModalWrapper'
import Grid2Columns from '../../components/layouts/Grid2Columns'

class ConfirmDeleteItemFormComponent extends Component {
  handleFormSubmit = e => {
    e.preventDefault()
    this.props.dispatch(deleteCalculatorItem(this.props.item))
  }
  render () {
    return (
      <ModalWrapper {...this.props}>
        <form className="ConfirmDeleteItemForm" onSubmit={this.handleFormSubmit}>
          <h3 className="AppFormTitle">Confirm Delete Item</h3>
          <div className="AppInput">
            <p>Are you sure you would like to delete <em>{this.props.item.title}</em> item?</p>
          </div>
          <Grid2Columns>
            <div className="AppSubmit">
              <button className="dark" type="submit">Delete</button>
            </div>
          </Grid2Columns>
        </form>
      </ModalWrapper>
    )
  }
}

let ConfirmDeleteItemForm = connect()(ConfirmDeleteItemFormComponent)

export default ConfirmDeleteItemForm
