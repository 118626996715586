import * as moment from 'moment'
/**
 * presentation helpers used to format data in component render functions
 */
export function presentMoment(m, format = 'MMM D, YYYY') {
  return m.format(format)
}

export const dateInputFormat = 'MM/DD/YYYY'

export function presentMomentAsDateInputValue(m) {
  return moment.isMoment(m) ? m.format('YYYY-MM-DD') : ''
}

export function presentDollarAmount(amount) {
  return amount.toLocaleString(
    undefined,
    {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
}
