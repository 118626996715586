import React, { Component } from 'react'
import { default as Content } from '../../store/pages/index'

import Header from '../Header'
import GridCenter from '../layouts/GridCenter'
import ContentBlock from '../ContentBlock'

class Index extends Component {
  render () {
    return (
      <GridCenter version="full">
        <Header version="center"/>
        <ContentBlock
          title={Content["content-block"]["title"]}
          version="center"
        />
      </GridCenter>
    )
  }
}

export default Index
