import { connect } from 'react-redux'
import ItemList from './ItemList'
import {
  openEditCalculatorItemModal,
  openEditCalculatorItemsModal,
  openConfirmDeleteItemModal,
  openConfirmDeleteSelected
} from '../../store/actions'
import { chronologically } from '../../helpers/sorters'

const mapStateToProps = (state, type) => {
  const { interestRate, compoundingModel } = state
  let items, total, title
  items = state.calculatorItems.filter(i => type === i.type)
  items.sort(chronologically)
  total = items.reduce((memo, item) => {return memo + item.amount}, 0)
  switch(type) {

  case 'PAYMENT_DUE':
    title = 'Total Amount Due'
    break

  case 'PAYMENT_MADE':
    title = 'Total Amount Paid'
    break

  case 'INTEREST_DUE':
    title = 'Interest Accrued'
    break

  case 'TOTAL_WITHOUT_INTEREST':
    title = 'Total Arrears Without Interest'
    items = state.calculatorItems.filter(i => 'INTEREST_DUE' !== i.type)
    items.sort(chronologically)
    total = items.reduce((memo, item) => {
      return 'PAYMENT_MADE' === item.type ? memo - item.amount : memo + item.amount
    }, 0)
    break

  default:
    title ='Total Arrears Including Interest'
    items = state.calculatorItems.slice(0)
    items.sort(chronologically)
    total = items.reduce((memo, item) => {
      return 'PAYMENT_MADE' === item.type ? memo - item.amount : memo + item.amount
    }, 0)
    break

  }
  total = Math.round(total * 100) / 100
  return {
    type,
    title,
    items,
    total,
    interestRate,
    compoundingModel
  }
}

const mapDispatchToProps = (dispatch, listType) => {
  return {
    editItem: id => {
      dispatch(openEditCalculatorItemModal(id))
    },
    editSelected: ids => {
      dispatch(openEditCalculatorItemsModal(ids))
    },
    deleteItem: id => {
      dispatch(openConfirmDeleteItemModal(id))
    },
    showComments: id => {
      console.log('TODO: implement showComments', id)
    },
    deleteSelected: ids => {
      dispatch(openConfirmDeleteSelected(ids))
    }
  }
}

const PaymentsDueList = connect(
  (state) => {return mapStateToProps(state, 'PAYMENT_DUE')},
  (dispatch) => {return mapDispatchToProps(dispatch, 'paymentsDue')}
)(ItemList)

const PaymentsMadeList = connect(
  (state) => {return mapStateToProps(state, 'PAYMENT_MADE')},
  (dispatch) => {return mapDispatchToProps(dispatch, 'paymentsMade')}
)(ItemList)

const InterestAccruedList = connect(
  (state) => {return mapStateToProps(state, 'INTEREST_DUE')},
  (dispatch) => {return mapDispatchToProps(dispatch, 'interestAccrued')}
)(ItemList)

const AllItemsList = connect(
  (state) => {return mapStateToProps(state, 'ALL_ITEMS')},
  (dispatch) => {return mapDispatchToProps(dispatch, 'allItems')}
)(ItemList)

const TotalWithoutInterestList = connect (
  (state) => {return mapStateToProps(state, 'TOTAL_WITHOUT_INTEREST')},
  (dispatch) => {return mapDispatchToProps(dispatch, 'totalWithoutInterest')}
)(ItemList)

export { PaymentsDueList, PaymentsMadeList, InterestAccruedList, AllItemsList, TotalWithoutInterestList }

