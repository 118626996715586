const ConfirmationMessages = {
  'ADD_PAYMENTS_DUE': 'Payments due successfully added.',
  'ADD_PAYMENTS_MADE': 'Payments made successfully added.',
  'EDIT_CALCULATOR_ITEM': 'Item successfully updated.',
  'EDIT_CALCULATOR_ITEMS_AMOUNTS': 'Item(s) successfully updated',
  'DELETE_CALCULATOR_ITEM': 'Item successfully deleted.',
  'DELETE_CALCULATOR_ITEMS': 'Item(s) successfully deleted.'
}

export default ConfirmationMessages
