// UI
export const SCROLL_TO_ELEMENT = 'SCROLL_TO_ELEMENT'

// modal
export const OPEN_ADD_PAYMENTS_DUE_MODAL = 'OPEN_ADD_PAYMENTS_DUE_MODAL'
export const OPEN_ADD_PAYMENTS_MADE_MODAL = 'OPEN_ADD_PAYMENTS_MADE_MODAL'
export const OPEN_EDIT_CALCULATOR_ITEM_MODAL = 'OPEN_EDIT_CALCULATOR_ITEM_MODAL'
export const OPEN_EDIT_CALCULATOR_ITEMS_MODAL = 'OPEN_EDIT_CALCULATOR_ITEMS_MODAL'
export const OPEN_CONFIRM_DELETE_ITEM_MODAL = 'OPEN_CONFIRM_DELETE_ITEM_MODAL'
export const OPEN_CONFIRM_DELETE_SELECTED_MODAL = 'OPEN_CONFIRM_DELETE_SELECTED_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

// editing
export const ADD_PAYMENTS_DUE = 'ADD_PAYMENTS_DUE'
export const ADD_PAYMENTS_MADE = 'ADD_PAYMENTS_MADE'
export const EDIT_CALCULATOR_ITEM = 'EDIT_CALCULATOR_ITEM'
export const EDIT_CALCULATOR_ITEMS_AMOUNTS = 'EDIT_CALCULATOR_ITEMS_AMOUNTS'
export const DELETE_CALCULATOR_ITEM = 'DELETE_CALCULATOR_ITEM'
export const DELETE_CALCULATOR_ITEMS = 'DELETE_CALCULATOR_ITEMS'

// date
export const UPDATE_CURRENT_DATE_ISO = 'UPDATE_CURRENT_DATE_ISO'

// configure
export const CONFIGURE_INTEREST_CALCULATOR = 'CONFIGURE_INTEREST_CALCULATOR'
