import React, { Component } from 'react'

class PlaceholderImage extends Component {
  render () {
    return (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <rect id="grayrect1" x="0%" y="0%" width="50%" height="50%" fill="rgb(245,245,245)" />
        <rect id="grayrect2" x="50%" y="0%" width="50%" height="50%" fill="rgb(89,106,118)" />
        <rect id="grayrect3" x="0%" y="50%" width="50%" height="50%" fill="rgb(89,106,118)" />
        <rect id="grayrect4" x="50%" y="50%" width="50%" height="50%" fill="rgb(245,245,245)" />
      </svg>
    )
  }
}

export default PlaceholderImage
