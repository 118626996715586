import React, { Component } from 'react'
import Expand from './Expand'
import './MainMenu.css'

class MainMenu extends Component {
  render () {
    return (
      <nav className="MainMenu">
        <ul className="MainMenu-main">
          <li className="MainMenu-main-item">FREE Calculators <Expand />
            <ul className="MainMenu-sub">
              <li className="MainMenu-sub-item"><a href="/free/child-support-interest-calculator">Child Support Interest</a></li>
              <li className="MainMenu-sub-item"><a href="/free/spousal-support-interest-calculator">Spousal Support Interest</a></li>
            </ul>
          </li>
          <li className="MainMenu-main-item"><a href="https://www.legalthunderapps.com/knowledge-base">Knowledge Base</a></li>
          <li className="MainMenu-main-item"><a href="https://www.legalthunderapps.com/about-us">About Us</a></li>
        </ul>
      </nav>
    )
  }
}

export default MainMenu
